html, body, #root {
  height: 100%;
}

.App {
  display: flex;
  height: 100%;
}

.left {
  /* display: flex; */
  width: 400px;
  /* background-color: gray; */
}

.right {
  flex-grow: 1;
  background-color: red;
  overflow: hidden;
}
.rendering {
  width: 100%;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2.5vmin);
  color: white;
  padding: 5px;
}

.header .headerSubtext {
  font-size: calc(8px + 1vmin);
}

.addActions {
  width: 100%;
}

.App-link {
  color: #61dafb;
}

.instructions .instructionsTitle{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
